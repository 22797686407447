import React from "react"
import Img, { FluidObject } from "gatsby-image"
import { Link } from "gatsby"

type Props = {
  heroImage: FluidObject
  description: string
}

const Hero = ({ heroImage, description }: Props) => {
  return (
    <section className="relative bg-white overflow-hidden">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <main className="mx-auto max-w-screen-xl px-4 pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none">
                Fysiotherapie&nbsp;
                <br />
                <span className="text-indigo-600">Wijnandsrade - Nuth</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {description}
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <Link to="/afspraak" className="btn btn-primary btn-cta btn-md">
                  Maak een afspraak
                </Link>
                <div className="flex flex-col justify-center">
                  <span className="md:ml-4 mt-4 lg:mt-0 text-gray-500">
                    Of bel naar:{" "}
                    <a
                      href="tel:0455241184"
                      className="text-gray-700 font-semibold underline"
                    >
                      045-5241184
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <Img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          fluid={heroImage}
        />
      </div>
    </section>
  )
}

export default Hero
