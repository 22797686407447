import React from "react"
import { graphql, PageProps } from "gatsby"
import { FluidObject } from "gatsby-image"

import Layout from "../components/Layout"
import Hero from "../components/home/Hero"
import Practices from "../components/home/practices/Practices"
import Contact from "../components/home/Contact"

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => (
  <Layout title="Home">
    <Hero
      heroImage={data.heroImage.childImageSharp.fluid}
      description={data.site.siteMetadata.description}
    />
    <Practices image={data.practiceImage.childImageSharp.fluid} />
    <Contact />
  </Layout>
)

export default IndexPage

type DataProps = {
  site: {
    siteMetadata: {
      description: string
    }
  }
  heroImage: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  practiceImage: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

export const query = graphql`
  {
    site {
      siteMetadata {
        description
      }
    }
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    practiceImage: file(relativePath: { eq: "man-carrying-backpack.jpg" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
