import React from "react"
import Practice from "./Practice"
import { FluidObject } from "gatsby-image"
import Image from "../../Image"

type Props = {
  image: FluidObject
}

const Practices = ({ image }: Props) => (
  <section className="container mx-auto py-24 px-8">
    <div className="flex">
      <div className="flex-1 hidden lg:flex flex-col justify-center">
        <Image
          src={image}
          alt="old man walking"
          className="max-w-sm rounded-md shadow-lg"
        />
      </div>
      <div className="flex-1">
        <h2 className="text-3xl font-extrabold text-gray-800">
          Onze praktijken
        </h2>

        <div className="flex flex-col">
          <Practice
            name="Fysiotherapie Souren"
            description="
Fysiotherapie Souren bestaat uit Yvonne Heij en Mark Souren, twee ervaren fysiotherapeuten met een veelzijdigheid aan kennis en ervaring.
Door steeds op de hoogte te blijven wat zich afspeelt binnen de mogelijkheden van de fysiotherapie staan beiden garant voor uw herstel. Het volgen van cursussen is dan ook een rode draad.
We zoeken samen met u naar een antwoord op uw vraag.
"
            link="/praktijken/mark-souren"
            phone="0455241184"
          />
          <Practice
            name="Fysiotherapie Karin"
            description="Fysiotherapie Karin -“ FYSIO AAN HUIS” - is specialist in ouderenzorg. Mijn praktijk heet “FYSIO AAN HUIS” omdat ik als geriatrie fysiotherapeut met
name bij cliënten thuis kom voor valanalyses, valpreventie training als ook revalidatie van
kwetsbare ouderen. Na een operatie , CVA, bij parkinson of andere redenen dat het zelfstandig
thuis blijven wonen moeilijker wordt kunt u mij inschakelen.
Mijn werk gebied ligt tussen gemeente Maastricht en de gemeente Beekdaelen.
Voor reguliere fysiotherapie kunt u bij mij terecht in de praktijk in Wijnandsrade."
            link="/praktijken/karin-van-havere"
            phone="0611344550"
          />
        </div>
      </div>
    </div>
  </section>
)

export default Practices
