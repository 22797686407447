import React from "react"
import InputField from "../form/Input-field"
import TextField from "../form/text-field"

const Contact = () => {
  return (
    <section className="w-full py-20">
      <div className="w-full container mx-auto">
        <div className="flex flex-col lg:flex-row mx-4">
          <div className="mr-10 prose">
            <h2>Contact</h2>
            <p>
              Neem contact met ons op via het contact formulier of bel ons via
              onderstaand telefoon nummer.
            </p>
            <h4 className="text-xl font-extrabold text-gray-800">
              Adres gegevens
            </h4>
            <span>Fysiotherapie Wijnandsrade-Nuth</span>
            <br />
            <span>Deckersstraat 26</span>
            <br />
            <span>6363 EB Wijnandsrade</span>
            <br />
            <span>
              Telefoon:{" "}
              <a
                href="tel:0455241184"
                className="text-gray-700 font-semibold underline"
              >
                045-5241184
              </a>
            </span>

            <h4 className="text-xl font-extrabold text-gray-800">
              Openingstijden
            </h4>
            <table id="opening-hours" className="table-auto">
              <tbody>
                <tr>
                  <td>Maandag</td>
                  <td>07:30 tot 19:30</td>
                </tr>
                <tr>
                  <td>Dinsdag</td>
                  <td>07:30 tot 17:00</td>
                </tr>
                <tr>
                  <td>Woensdag</td>
                  <td>07:30 tot 19:30</td>
                </tr>
                <tr>
                  <td>Donderdag</td>
                  <td>07:30 tot 17:00</td>
                </tr>
                <tr>
                  <td>Vrijdag</td>
                  <td>07:30 tot 17.00</td>
                </tr>
                <tr>
                  <td>Zaterdag</td>
                  <td>Op afspraak</td>
                </tr>
                <tr>
                  <td>Zondag</td>
                  <td>Gesloten</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div className="bg-white shadow-sm rounded px-8 pt-6 pb-8 mt-16 lg:mt-0">
              <form
                name="Contact"
                method="POST"
                data-netlify="true"
                netlify-honeypot="bot-field"
                action="/success/"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Contact" />
                <div className="flex flex-wrap mx-3 mb-6">
                  <InputField
                    label="Roepnaam"
                    name="roepnaam"
                    className="w-full md:w-1/2"
                  />
                  <InputField
                    label="Achternaam"
                    name="achternaam"
                    className="w-full md:w-1/2"
                  />
                </div>
                <div className="flex flex-wrap mx-3 mb-6">
                  <InputField
                    label="E-mailadres"
                    name="email"
                    placeholder="voorbeeld@voorbeeld.nl"
                    className="w-full"
                  />
                </div>
                <div className="flex flex-wrap mx-3 mb-6">
                  <TextField label="Vraag" name="vraag" className="w-full" />
                </div>
                <div className="flex flex-wrap mx-3 mb-6">
                  <button type="submit" className="btn btn-primary mx-3">
                    Verzend
                  </button>
                </div>
                <small className="mx-6">
                  (<span className="text-red-500">*</span>) Verplichte velden
                </small>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
