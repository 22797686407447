import React from "react"
import { Link } from "gatsby"

type Props = {
  name: string
  description: string
  link: string
  phone: string
}

const Practice = ({ name, description, link, phone }: Props) => {
  return (
    <div className="mt-5">
      <h4 className="text-xl font-semibold text-gray-800">
        {name} -{" "}
        <a href={`tel:${phone}`} className="text-sm text-gray-700">
          {phone}
        </a>
      </h4>
      <p className="mt-2 text-gray-500 md:text-lg">{description}</p>
      <Link
        to={link}
        className="block text-indigo-600 mt-2 font-semibold hover:underline"
      >
        Meer informatie over deze praktijk
        <i className="fas fa-arrow-right fa-xs" />
      </Link>
    </div>
  )
}

export default Practice
