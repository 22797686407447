import React from "react"
import Img, { FixedObject, FluidObject } from "gatsby-image"

function instanceOfFixedObject(object: any): object is FixedObject {
  return "height" in object
}

function instanceOfFluidObject(object: any): object is FluidObject {
  return "aspectRatio" in object
}

type Props = {
  src: FluidObject | FixedObject | string
  alt: string
  className?: string
}

const Image = ({ src, alt, className }: Props) => {
  if (instanceOfFluidObject(src)) {
    return (
      <Img
        fluid={src}
        alt={alt}
        className={className ? className : "rounded"}
      />
    )
  }

  if (instanceOfFixedObject(src)) {
    return (
      <Img
        fixed={src}
        alt={alt}
        className={className ? className : "rounded"}
      />
    )
  }

  if (typeof src === "string") {
    return (
      <img src={src} alt={alt} className={className ? className : "rounded"} />
    )
  }

  return (
    <p className="text-red-500">
      Please provide a fluid, fixed or image string
    </p>
  )
}

export default Image
